import yo from "../assets/img/yop.png"
import yoni from "../assets/img/yonatan.png"
import yonitengo from "../assets/img/yoni.png"

export const Presentacion = () => {
    return (
        <div className="flex flex-col pt-16">

            <img src={yoni} alt="yo" className="sm:w-1/2 md:w-1/5 mx-auto rounded-full" />

        </div>
    )
}